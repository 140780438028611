import VimeoPlayer from '@vimeo/player';

export const testimonialMediaInit = () => {
  const testimonialMediaComponents = document.querySelectorAll('.c-testimonialmedia') as NodeList;

  const handleModalClose = () => {
    window.Bus.on('emu-modal:close', ({ id }) => {
      const modal = document.querySelector(`[data-id="${id}"]`) as HTMLDivElement;
      const iframe = modal.querySelector('.emu-embed iframe') as HTMLIFrameElement;
      const player = new VimeoPlayer(iframe);
      player.pause();
    });
  }

  if (testimonialMediaComponents.length) {
    handleModalClose();
  }
}