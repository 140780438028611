export const headerInit = () => {
  const chevronDown = () => {
    return `
      <?xml version="1.0" encoding="UTF-8"?>
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Chevron Down</title>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-3" stroke="currentColor" transform="translate(9.500000, 9.500000) rotate(-270.000000) translate(-9.500000, -9.500000) translate(4.000000, 4.000000)">
            <g id="Group-2" transform="translate(3.500000, 0.000000)">
              <polyline id="Path-3" points="0 0 5.5 5.5 0 11"></polyline>
            </g>
          </g>
        </g>
      </svg>
    `;
  }

  const stripCountryCode = (hrefLang: string): string => {
    const regex = /^[a-z]{2}-[a-z]{2}$/i;

    if (regex.test(hrefLang)) {
      return hrefLang.split('-')[0];
    }

    return hrefLang;
  }

  const addLanguageNavigationSwitcher = (languageNavigation) => {
    const activeLanguageLink = languageNavigation.querySelector('.cmp-languagenavigation__item--active > .cmp-languagenavigation__item-link') as HTMLAnchorElement;
    const languageLink = languageNavigation.querySelector('.cmp-languagenavigation__item > .cmp-languagenavigation__item-link') as HTMLAnchorElement;
    const languageNavigationGroup = languageNavigation.querySelector('.cmp-languagenavigation__group') as HTMLElement;
    const language = activeLanguageLink ? activeLanguageLink : languageLink;
    const languageCode = stripCountryCode(language.hreflang);
    const activeLanguage = languageCode.toUpperCase();
    const anchor = document.createElement('a');
    const text = document.createTextNode(activeLanguage);
    anchor.appendChild(text);
    anchor.classList.add('cmp-languagenavigation__switcher');
    anchor.href = 'javascript:void(0);';
    const span = document.createElement('span');
    span.innerHTML = chevronDown();
    anchor.appendChild(span);
    languageNavigation.insertBefore(anchor, languageNavigationGroup);
  }

  const toggleClass = (el, className) => {
    el.classList.toggle(className);
  }

  const addLanguageNavigationEvents = (languageNavigation) => {
    const languageNavigationGroup = languageNavigation.querySelector('.cmp-languagenavigation__group') as HTMLElement;
    const languageNavigationSwitcher = languageNavigation.querySelector('.cmp-languagenavigation__switcher') as HTMLAnchorElement;

    languageNavigationSwitcher.addEventListener('click', () => {
      toggleClass(languageNavigationGroup, 'active');
      toggleClass(languageNavigationSwitcher, 'active');
    });
  }

  const handleHeaderStickyPosition = () => {
     window.addEventListener(
      'scroll',
      () => {
        const header = document.querySelector('header.header') as HTMLElement;
        const headerHeight = header.offsetHeight;

        if (window.scrollY > headerHeight) {
          header.classList.add('header--sticky');
        } else {
          header.classList.remove('header--sticky');
        }
      }
    );
  }

  const setResizeObserver = () => {
    const xlbp = getComputedStyle(document.documentElement).getPropertyValue('--emu-semantic-sizing-breakpoints-x-large');
    const mqxl = window.matchMedia(`(min-width: ${xlbp})`);

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(() => {
        if (mqxl.matches && mobileNavigationModal.classList.contains('is-open')) {
          mobileNavigationCloseButton.click();
        }
      });
    });

    resizeObserver.observe(mobileNavigationModal);
  }

  const mobileNavigationModal = document.querySelector('.c-navigation--mobile > .modal-wrapper') as HTMLElement;
  const mobileNavigationCloseButton = mobileNavigationModal.querySelector('.main-close') as HTMLButtonElement;
  const languageNavigations = document.querySelectorAll('.cmp-languagenavigation');

  languageNavigations.forEach(languageNavigation => {
    addLanguageNavigationSwitcher(languageNavigation);
    addLanguageNavigationEvents(languageNavigation);
  });

  handleHeaderStickyPosition();
  setResizeObserver();
}