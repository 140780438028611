import { locationServicesFormInit } from '../locationServices/locationServices';

export const searchLocationInit = () => {
  const staticSelectors = {
    componentClassName: '.c-search-location',
    searchArea: '.c-search-area',
    searchLocationCountries: '.c-search-location__countries',
    countrySelectWrapper: '.emu-location-services__country-select-wrapper',
    countrySelect: '.emu-location-services__country-select-wrapper select',
    locationButton: '.c-search-location__btn-container .cmp-button'
  };

  const extractCountryCode = (element: HTMLAnchorElement): string | null => {
    const elementClasses = element.className || '';
    const countryCodeRegex = /js-([a-z]{2})/;
    const match = countryCodeRegex.exec(elementClasses) ?? null;
    return match ? match[1] : null;
  };

  const toggleButtonVisibility = (button: HTMLAnchorElement, country: string, countryCode: string) => {
    button.parentElement!.classList.toggle('hidden', countryCode !== country);
  };

  const filterLocationButtons = (country: string): void => {
    const locationButtons: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(staticSelectors.locationButton);

    locationButtons.forEach(button => {
      const countryCode = extractCountryCode(button) || '';
      toggleButtonVisibility(button, country, countryCode);
    });
  };

  const displaySearchArea = (): void => {
    const searchArea = document.querySelector(staticSelectors.searchArea) as HTMLDivElement;
    searchArea.classList.remove('hidden');
  };

  const registerEvents = (): void => {
    const countrySelect = document.querySelector(staticSelectors.countrySelect) as HTMLSelectElement;

    if (countrySelect) {
      window.Bus.on('emu-location-services:countrySelect', ({ country }: { country: string }) => {
        filterLocationButtons(country as string);
        displaySearchArea();
      });
    }
  };

  const constructor = (): void => {
    registerEvents();
  };

  const appendCountrySelect = (): void => {
    const searchLocationCountries = document.querySelector(staticSelectors.searchLocationCountries) as HTMLDivElement;
    const countrySelectWrapper = document.querySelector(staticSelectors.countrySelectWrapper) as HTMLDivElement;

    if (searchLocationCountries && countrySelectWrapper) {
      searchLocationCountries.appendChild(countrySelectWrapper);
    }
  };

  const hideLocationButtons = (): void => {
    const locationButtons: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(staticSelectors.locationButton);

    locationButtons.forEach((button) => {
      button.parentElement!.classList.add('hidden');
    })
  };

  const handleCountrySelect = (element: Element): void => {
    const hasCountrySelect = element.querySelector(staticSelectors.countrySelectWrapper) !== null;

    if (hasCountrySelect) {
      const searchArea = document.querySelector(staticSelectors.searchArea) as HTMLDivElement;

      if (searchArea instanceof HTMLElement) {
        searchArea.classList.add('hidden');
      }

      appendCountrySelect();
      hideLocationButtons();
    }
  };

  const addButtonTitles = (element: Element): void => {
    const buttons = element.querySelectorAll('.emu-button');
    
    if (buttons.length > 0) {
      buttons.forEach(button => {
        button.setAttribute('title', button.ariaLabel || '');
      })
    }
  };

  const init = (): void => {
    const elements: NodeListOf<HTMLElement> = document.querySelectorAll<HTMLElement>(staticSelectors.componentClassName);

    if (elements.length > 0) {
      elements.forEach((element) => {
        addButtonTitles(element);
        constructor();
        locationServicesFormInit(element);
        handleCountrySelect(element);
      });
    }
  };

  init();
}