export const treatmentAreasInit = () => {
  const component = document.querySelectorAll<HTMLDivElement>(
    '.c-treatmentareas'
  );
  component.forEach(component => {
    if (!component) {
      return;
    }
    let slideIndexFemale = 1;
    let slideIndexMale = 1;
    const slidesFemale = Array.from(component.querySelectorAll<HTMLDivElement>('.c-treatmentareas-female > .container'));
    const slidesMale = Array.from(component.querySelectorAll<HTMLDivElement>('.c-treatmentareas-male > .container'));
    const markersFemale = Array.from(component.querySelectorAll<HTMLButtonElement>('.c-treatmentareas-female .emu-mapping-marker'));
    const markersMale = Array.from(component.querySelectorAll<HTMLButtonElement>('.c-treatmentareas-male .emu-mapping-marker'));
    const femaleAnimationSpans = component.querySelectorAll<HTMLButtonElement>(
      '.image:first-child button.emu-mapping-marker span'
    );
    const maleAnimationSpans = component.querySelectorAll<HTMLButtonElement>(
      '.image:nth-child(2) button.emu-mapping-marker span'
    );
    // creating span elements for rotate animation at markers and appending them to wrapper
    const leftHalfCircle = document.createElement('span');
    const rightHalfCircle = document.createElement('span');
    leftHalfCircle.setAttribute('class', 'rotateLeft');
    rightHalfCircle.setAttribute('class', 'rotateRight');
    femaleAnimationSpans.forEach(span => {
      span.setAttribute('class', 'circle-wrapper');
      span.appendChild(leftHalfCircle.cloneNode(true));
      span.appendChild(rightHalfCircle.cloneNode(true));
    });
    maleAnimationSpans.forEach(span => {
      span.setAttribute('class', 'circle-wrapper');
      span.appendChild(leftHalfCircle.cloneNode(true));
      span.appendChild(rightHalfCircle.cloneNode(true));
    });
    const treatmentAreas = (slides: any[], markers: any[], slideIndex) => {
      const showSlides = n => {
        if (n > slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = slides.length;
        }
        slides.forEach(slide => slide?.classList.remove('visible'));
        markers.forEach(marker => {
          marker?.classList.remove('overlay');
          marker?.classList.remove('active');
        });
        slides[slideIndex - 1]?.classList.add('visible');
        markers[slideIndex - 1]?.classList.add('overlay');
        markers[slideIndex]?.classList.add('active');
      };
      const plusSlides = n => {
        showSlides((slideIndex += n));
      };
      const currentSlide = n => {
        showSlides((slideIndex = n));
      };
      showSlides(slideIndex);
      markers.forEach((dot, i) => {
        dot.addEventListener('click', () => {
          currentSlide(i + 1);
        });
      });
      setInterval(() => {
        plusSlides(1);
      }, 7000);
    };
    treatmentAreas(slidesFemale, markersFemale, slideIndexFemale);
    treatmentAreas(slidesMale, markersMale, slideIndexMale);
  });
};
