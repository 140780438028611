// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';
import './pages/**/*.scss';

// import favicon here.
const faviconLink = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
faviconLink.href = '/content/dam/coolsculpting-ous/favicon.ico';

import { headerInit } from './components/header/header';
import { clinicsInit } from './pages/clinics/clinics';
import { teaserInit } from './components/teaser/teaser';
import { findClinicFormInit } from './components/findClinicForm/findClinicForm';
import { topicNavigationInit } from './components/topicNavigation/topicNavigation';
import { treatmentAreasInit } from './components/treatmentAreas/treatmentAreas';
import { searchLocationInit } from './components/searchLocation/searchLocation';
import { testimonialMediaInit } from './components/testimonialMedia/testimonialMedia';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
    _googleMapInstances: any; // google map instances
  }
}

const init = () => {
  if (window.Bus) {

  }

  headerInit();
  teaserInit();
  findClinicFormInit();
  clinicsInit();
  searchLocationInit();
  topicNavigationInit();
  testimonialMediaInit();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
  window.addEventListener('load', () => {
    treatmentAreasInit();
  })
} else {
  init();
  window.addEventListener('load', () => {
    treatmentAreasInit();
  })
}