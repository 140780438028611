export const topicNavigationInit = (): void => {
  const topicNavigation = document.querySelector('.c-topicnavigation') as HTMLDivElement;

  if (topicNavigation) {
    const activeItemClass = 'emu-navigation__item--active';
    const topicMenuNavigation = document.querySelector('.emu-navigation') as HTMLElement;
    const sections = document.querySelectorAll('.c-topicnavigation__section');
    const anchors = topicNavigation.querySelectorAll<HTMLAnchorElement>('.emu-navigation__menu--level-0 a');
    const header = document.querySelector('header.header') as HTMLElement;
    const headerHeight = header.offsetHeight;
    const topicNavigationHeight = topicMenuNavigation.offsetHeight;
    const pageOffsetTop = headerHeight + topicNavigationHeight;


    const handleAnchorClick = (anchors: NodeListOf<HTMLAnchorElement>): void => {
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', (e: MouseEvent) => {
          e.preventDefault();
          anchor.parentElement?.classList.remove(activeItemClass);
          scrollToSection(anchor);
        });
      });
    };

    const getAnchorId = (anchor: HTMLAnchorElement): string => {
      return anchor.hash ? anchor.hash.replace('#', '') : '';
    };

    const handleActiveMenuItem = (): void => {
      let current: string | null = null;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i] as HTMLElement;
        const sectionTop = section.offsetTop;

        if (window.scrollY >= sectionTop - pageOffsetTop) {
          current = section.getAttribute('id');
        } else {
          break;
        }
      }

      anchors.forEach((anchor) => {
        const anchorId = getAnchorId(anchor as HTMLAnchorElement);
        const listItem = anchor.parentElement as HTMLLIElement;

        listItem.classList.remove(activeItemClass);

        if (current && anchorId === current) {
          listItem.classList.add(activeItemClass);
        }
      });
    };


    const scrollToSection = (anchor: HTMLAnchorElement): void => {
      const section = document.getElementById(getAnchorId(anchor)) as HTMLElement;
      const sectionGap = 5;
      const sectionOffsetTop = section.getBoundingClientRect().top + window.scrollY - pageOffsetTop + sectionGap;

      window.scrollTo({
        top: sectionOffsetTop,
        behavior: 'smooth'
      });
    };

    const topicNavigationWrapper = topicNavigation.parentElement as HTMLDivElement;
    topicNavigationWrapper.classList.add('c-topicnavigation__wrapper');
    window.addEventListener('scroll', handleActiveMenuItem);
    handleAnchorClick(anchors);
  }
};
