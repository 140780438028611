const _temp0 = require("./pages/clinics/_clinicPopup.scss");
const _temp1 = require("./pages/clinics/_clinicsList.scss");
const _temp2 = require("./pages/clinics/_modal.scss");
const _temp3 = require("./pages/clinics/_refineSearch.scss");
const _temp4 = require("./pages/clinics/_tabs.scss");

module.exports = {
  "clinics":   {
    "_clinicPopup": _temp0,
    "_clinicsList": _temp1,
    "_modal": _temp2,
    "_refineSearch": _temp3,
    "_tabs": _temp4
  }
}